import React from "react";
import '../src/style.css'
import $ from "jquery";


function App() {

    const links = {
        Twitter: "https://x.com/bytecattool",
        Etherscan: "https://etherscan.io/address/0xcF3B6691b4ED6395b370d264eDE557373Ea0054a",
        Dextools: "https://www.dextools.io/app/en/ether/pair-explorer/0xb1258206397e47caf1817ab6e2c9dcd29be97c98?t=1709839144742",
        TG: "https://t.me/bytecattool",
        UNISWAP: "https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0xcF3B6691b4ED6395b370d264eDE557373Ea0054a",
    }

    const handleCopy = () => {
        const textToCopy = "0xcF3B6691b4ED6395b370d264eDE557373Ea0054a";
        navigator.clipboard.writeText(textToCopy)
            .catch(err => {
                console.error('Failed to copy:', err);
            });
    };


    return (
        <>
            <div className="wbackground">
                <div className="nav">
                    <div className="nav-menu">
                        <div className="main-logo">
                            <div>
                                <img src="img/logo1.svg" alt="logo" />
                            </div>
                        </div>
                        <div className="menu">
                            <div className="connect" style={{ color: "rgba(89, 89, 89, 1)" }}>
                                LAUNCH APP
                            </div>
                        </div>
                    </div>
                </div>
                <div class="erc404">
                    <div class="erc404-info">
                        <div class="erc404-info__text">
                            <p><span style={{ color: "#C6FF25", fontStyle: "italic", fontWeight: "400" }}>$BYTE</span></p>
                            <p>ADVANCED SNIPER FOR</p>
                            <p>ADVANCED TRADERS</p>
                        </div>
                        <div class="erc404-info__about">
                            <p style={{ textTransform: "uppercase" }}>The ultimate tool for advanced traders seeking to dominate the market with precision and security</p>
                        </div>
                        <div class="erc404-info__btn" style={{ flexWrap: "wrap" }}>
                            <div class="mint-btn" style={{ paddingTop: "11.5px", paddingBottom: "11.5px" }}><a href={links.UNISWAP}>BUY $BYTE ON UNISWAP</a></div>
                            <ul className="soc" style={{ gap: "12px" }}>
                                <li className="btn-hover">
                                    <a href={links.TG} target="_blank" rel="noreferrer"><img src="img/tg.svg" alt="TG" /></a>
                                </li>
                                <li className="btn-hover">
                                    <a href={links.Etherscan} target="_blank" rel="noreferrer"><img src="img/eth.svg" alt="Etherscan" /></a>
                                </li>
                                <li className="btn-hover">
                                    <a href={links.Twitter} target="_blank" rel="noreferrer"><img src="img/x.svg" alt="X" /></a>
                                </li>
                                <li className="btn-hover">
                                    <a href={links.Dextools} target="_blank" rel="noreferrer">
                                        <img src="img/dt.svg" alt="Dextools" width="25" height="25" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="erc404-info__text" style={{ marginBottom: "40px" }}>
                            <p onClick={handleCopy} style={{ fontSize: "16px", fontWeight: 400, lineHeight: "15px", display: "block", letterSpacing: "-4%", background: "#373737", padding: "11.5px 20px", width: "400px", cursor: "pointer" }}>0xcF3B6691b4ED6395b370d264eDE557373Ea0054a</p>
                        </div>
                    </div>
                    <div class="erc404-img">
                        <img src="img/cat.svg" alt="Cat" />
                    </div>
                </div>
            </div>
            <div class="footer" style={{ marginTop: "0px"}}>
                <p>2024 (c) BYTECATS</p>
            </div>


        </>
    );
}

export default App;
